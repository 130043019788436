import React, { useState, useEffect } from "react";
import { Form, Button, Steps, message, Modal } from "antd";

import { editApply, getApply, downloadApply } from "../../apis/api";
import "./index.css";
import { useLocation } from "react-router-dom";
import { Input, InputNumber, Radio, Upload } from "antd";
import { baseApiUrl } from "../../config";
import type { UploadProps } from "antd";

const FundingApplicationForm: React.FC = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [applyId, setApplyId] = useState("");
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    // 从路由中获取 applyId
    const queryParams = new URLSearchParams(location.search);
    const applyId = queryParams.get("applyId");
    console.log("路径applyId====>", applyId);
    console.log("路径applyId====>", applyId);
    // 模拟从接口获取数据
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await getApply(applyId);
        if (response.status && response.data) {
          const data = response.data;

          setApplyId(data.id);

          // 根据步骤的 key 字段赋值
          const newData = {} as any;
          if (data.contract) {
            const tmp = JSON.parse(data.contract);
            for (const key in tmp) {
              newData[key] = tmp[key];
            }
          }

          form.setFieldsValue(newData);

          // 强制刷新组件
          // form.validateFields();
        }
      } catch (error) {
        message.error("获取数据失败");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [form]);

  const handleUploadChange = (info: any, fieldName: string) => {
    if (info.file.status === "done") {
      const response = info.file.response;

      if (response.status) {
        const uploadedUrl = response.data.url;
        form.setFieldsValue({
          [fieldName]: [
            {
              uid: response.data.fileId,
              name: info.file.name,
              status: "done",
              url: uploadedUrl,
            },
          ],
        });
      }
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} 文件上传失败`);
    }
  };

  const [props, setProps] = useState<UploadProps>({
    name: "file",
    action: `${baseApiUrl}/upload-file`,
    headers: {},

  });

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      console.log("values", values);

      const res = await editApply({
        applyId: applyId,
        contract: values,
      });
      if (res.status) {
        message.success("提交成功");
        // 延迟 0.5s
        setTimeout(() => {
          window.open("/");
        }, 500);
      }
    } catch (error) {
      message.error("提交失败");
    }
  };

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    if (token) {
      setProps({
        ...props,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
  }, []);

  return (
    <div className="funding-application-container">
      <Form form={form} layout="vertical" onFinish={() => {}}>
        {/* <Form.Item
          label="申请单位承诺页(盖章、时间填写)"
          name="apply_unit_promise"
          valuePropName="fileList"
          rules={[{ required: true, message: "请上传申请单位承诺页" }]}
          getValueFromEvent={(e) => {
            if (Array.isArray(e)) {
              return e;
            }
            return e && e.fileList;
          }}
        >
          <Upload
            {...props}
            onChange={(info) => handleUploadChange(info, "apply_unit_promise")}
          >
            <Button type="link"> 上传文件</Button>
          </Upload>
        </Form.Item> */}

        {/* <Form.Item
          label="申请单位基本简介页（申请单位盖章）"
          name="apply_unit_intro"
          valuePropName="fileList"
          rules={[{ required: true, message: "请上传申请单位基本简介页" }]}
          getValueFromEvent={(e) => {
            if (Array.isArray(e)) {
              return e;
            }
            return e && e.fileList;
          }}
        >
          <Upload
            {...props}
            onChange={(info) => handleUploadChange(info, "apply_unit_intro")}
          >
            <Button type="link"> 上传文件</Button>
          </Upload>
        </Form.Item> */}

        {/* <Form.Item
          label="经费用途页（制表人签字，财务负责人签字，财务专用章盖章）"
          name="project_funding_usage"
          valuePropName="fileList"
          rules={[{ required: true, message: "请上传经费用途页" }]}
          getValueFromEvent={(e) => {
            if (Array.isArray(e)) {
              return e;
            }
            return e && e.fileList;
          }}
        >
          <Upload
            {...props}
            onChange={(info) =>
              handleUploadChange(info, "project_funding_usage")
            }
          >
            <Button type="link"> 上传文件</Button>
          </Upload>
        </Form.Item> */}

        <Form.Item
          label="上传申请书签字、盖章后的扫描件"
          name="project_sealed_document"
          valuePropName="fileList"
          rules={[
            { required: true, message: "上传申请书签字、盖章后的扫描件" },
          ]}
          getValueFromEvent={(e) => {
            if (Array.isArray(e)) {
              return e;
            }
            return e && e.fileList;
          }}
        >
          <Upload
            {...props}
            onChange={(info) =>
              handleUploadChange(info, "project_sealed_document")
            }
          >
            <Button type="link"> 上传文件</Button>
          </Upload>
        </Form.Item>

        <Button type="primary" onClick={handleSubmit}>
          提交文件
        </Button>
      </Form>
    </div>
  );
};

export default FundingApplicationForm;

import React, { useState, useEffect } from "react";
import { Form, Input, Button, message, Row, Col } from "antd";
import { register, sendRegisterVerCode } from "../../apis/api";
import { useAuth } from "../../configs/AuthContext";
import { useNavigate } from "react-router-dom";
import "./index.css"; // New CSS file for styles
import logo from "../../assets/logo.jpg"; // Assuming logo is in assets folder

interface User {
  id: string;
  email: string;
}

const RegisterForm: React.FC = () => {
  const [form] = Form.useForm();
  const { setUser } = useAuth();
  const navigate = useNavigate();
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [countdown, setCountdown] = useState(30);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isCodeSent && countdown > 0) {
      timer = setTimeout(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else if (countdown === 0) {
      setIsCodeSent(false);
      setCountdown(30);
    }
    return () => clearTimeout(timer);
  }, [isCodeSent, countdown]);

  const handleRegister = async (values: {
    email: string;
    password: string;
    code: string;
  }) => {
    try {
      const { email, password, code } = values;
      const response = await register(email, password, code);
      console.log("注册==》", response);
      if (response.status) {
        sessionStorage.setItem("token", response.data.token);
        setUser(response.data.user);
        message.success("注册成功");
        navigate("/");
      } else {
        message.error(response.message || "注册失败");
      }
    } catch (error) {
      message.error("注册失败，请检查邮箱格式或密码强度");
      console.error("Error registering: ", error);
    }
  };

  const handleSendCode = async () => {
    try {
      const email = form.getFieldValue("email");
      if (!email) {
        message.error("请输入邮箱地址");

        return;
      }
      setLoading(true);
      const res = await sendRegisterVerCode(email);

      // 由于拦截器已返回 response.data，所以直接访问属性
      if (res.status) {
        setIsCodeSent(true);
        message.success("验证码已发送至您的邮箱");
      } else {
        message.error(res.message || "发送失败");
      }
      setLoading(false);
    } catch (error) {
      message.error("发送验证码失败，请稍后再试");
      console.error("Error sending code: ", error);
    }
  };

  return (
    <div className="register-container">
      <div className="login-time">申报截止时间:2024年12月12日24:00</div>

      <div className="register-box">
        <img src={logo} alt="Logo" className="register-logo" />
        <h3>上海科普教育发展基金会社会资助项目填报系统</h3>

        <Form
          layout="vertical"
          onFinish={handleRegister}
          form={form}
          className="register-form"
        >
          <h2>注册</h2>

          <Form.Item
            label="邮箱"
            name="email"
            rules={[
              {
                required: true,
                type: "email",
                message: "请输入有效的电子邮箱",
              },
            ]}
          >
            <Input placeholder="请输入电子邮箱" />
          </Form.Item>
          <Form.Item>
            <Row gutter={8}>
              <Col span={16}>
                <Form.Item
                  name="code"
                  noStyle
                  rules={[{ required: true, message: "请输入验证码" }]}
                >
                  <Input placeholder="请输入验证码" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Button
                  onClick={handleSendCode}
                  disabled={isCodeSent}
                  loading={loading}
                >
                  {isCodeSent ? `请等待(${countdown}s)` : "获取验证码"}
                </Button>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item
            label="密码"
            name="password"
            rules={[{ required: true, message: "请输入密码" }]}
          >
            <Input.Password placeholder="请输入密码" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              注册
            </Button>
          </Form.Item>
        </Form>
      </div>
      <div className="bottom">
        <div className="first">主办方:上海科普教育发展基金会主办</div>
        <div>联系电话：傅老师 64225391-811</div>
      </div>
    </div>
  );
};

export default RegisterForm;
